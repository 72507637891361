.mat-toolbar.mat-primary {
  overflow: hidden;
  background-color: rgb(33,33,35);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);
  //border-bottom: 1px solid #323232;//1e1e1e //2d2f49 //323232
}

.mat-toolbar .mat-toolbar-row:first-child {
  padding: 0;
}
// @media only screen and (min-width: 600px) {
//   .mat-toolbar .mat-toolbar-row:first-child {
//     padding: 0;
//   }
// }
.mat-toolbar .mat-mdc-button-base.mat-mdc-button-base.mat-unthemed .mat-icon{
  -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.05)) drop-shadow(-1px -1px 0px rgba(255, 255, 255, 0.05)) drop-shadow(1px 1px 0px rgb(0, 0, 0, .5)) drop-shadow(0px 0px 2px rgb(0, 0, 0, .5));
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.05)) drop-shadow(-1px -1px 0px rgba(255, 255, 255, 0.05)) drop-shadow(1px 1px 0px rgb(0, 0, 0, .5)) drop-shadow(0px 0px 2px rgb(0, 0, 0, .5))
  
}


.mat-toolbar-row {
  overflow: hidden;
}
