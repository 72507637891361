
  @font-face {
    font-family: "Roboto-Regular";
    src: url("../fonts/roboto/Roboto-Regular.eot");
    src: url("../fonts/roboto/Roboto-Regular.eot#iefix") format("embedded-opentype"),
      url("../fonts/roboto/Roboto-Regular.woff") format("woff"),
      url("../fonts/roboto/Roboto-Regular.ttf") format("truetype"),
      url("../fonts/roboto/Roboto-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }  
  @font-face {
    font-family: "Quicksand";
    src: local("Quicksand"), url("../fonts/quicksand/Quicksand-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  body {
    font-family: "Roboto-Regular", Helvetica, arial;
  }



