@import './a-material/global.scss';
@import '../../node_modules/material-icons/iconfont/material-icons.css';


.disable-selection {
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/
}


